import GSAP from 'gsap'
import { Animations } from '../helpers/Animations'

export default class Menu {
	constructor(el, index) {
		this.element 		= el
		this.index 			= index
		this.menuToggler 	= this.element.querySelector('.c-Menu__toggler__btn')
		this.navLinks 		= this.element.querySelectorAll('.c-Menu__navigation a')
		this.contactLinks 	= this.element.querySelectorAll('.c-Menu__contact__address, .c-Menu__contact__social')

		this.isExpanded		= false

		this.mm 			= GSAP.matchMedia()

		this.stateChangeHandler = (e) => { 
			this.isExpanded  = !this.isExpanded
			this.stateChange()
		}
	}
  
	init() {

		this.mm.add({
			isDesktop: `(min-width: 855px)`,
			isMobile: `(max-width: 854px)`
		}, (context) => {
			let { isDesktop, isMobile } = context.conditions

				// if(isDesktop && this.isExpanded === true) {
				// 	this.close()
				// 	console.log('isdesktop & isExpanded')
				// }

			return () => { 
				// clean up stuff that is run when breakpoint no
				// longer matches
				if(isMobile && this.isExpanded === true) {
					this.stateChangeHandler()
					//console.log('toggled')
				}
			}
		})

		window.emitter.on('menuCalled', this.stateChangeHandler)
		this.menuToggler.addEventListener('click', this.stateChangeHandler)
	}

	stateChange() {
		if(this.isExpanded === true) {
			this.open()
		} else {
			this.close()
		}
	}

	open() {

		this.tl = GSAP.timeline()

		this.tl.set(this.navLinks, {
			y: '100%',
		})
		this.tl.set(this.contactLinks, {
			opacity: 0
		}, '<')
		this.tl.to(this.element, {
			x: 0
		})
		this.tl.to(this.navLinks, {
			y: 0,
			ease: Animations.ease.generic,
			duration: Animations.timing.links,
			stagger: Animations.stagger.links,
		})
		this.tl.to(this.contactLinks, {
			opacity: 1,
			duration: Animations.timing.opacity,
			ease: Animations.ease.generic,
			stagger: Animations.stagger.links,
		}, '-=0.3')
	}

	close() {

		this.tlClose = GSAP.timeline()

		this.tlClose.to(this.contactLinks, {
			opacity: 0,
			duration: Animations.timing.opacity,
			ease: Animations.ease.generic,
			stagger: Animations.stagger.links,
		})
		this.tlClose.to(this.navLinks, {
			y: '-100%',
			ease: Animations.ease.generic,
			duration: Animations.timing.links,
			stagger: Animations.stagger.links,
		}, '<')
		this.tlClose.to(this.element, {
			x: '100%'
		}, '-=0.5')
	}

	unmount() {

		this.isExpanded 	= false
		window.emitter.off('menuCalled', this.stateChangeHandler)
		this.menuToggler.removeEventListener('click', this.stateChangeHandler)
	}
}
