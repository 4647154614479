import GSAP from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

GSAP.registerPlugin(ScrollTrigger)

export default class Video {
	constructor(el, index) {
		this.element 	= el
		this.videoSrc 	= this.element.querySelector('.c-Video source')
		this.video 		= this.element.querySelector('.c-Video video')
		this.index 		= index

		this.canPlay 	= false

		this.scrollTriggerLoader = null
		this.scrollTriggerState = null

		this.dataOptions			= this.element.getAttribute('data-type')
		this.parsedData 			= JSON.parse(this.dataOptions)
	}
  
	init() {

		if(this.parsedData.type == 'mediaLibrary') {

			this.loadVideo()
		}
	}

	loadVideo() {
		this.scrollTriggerLoader = ScrollTrigger.create({
			trigger: this.element,
			start: 'top bottom',
			once: true,
			onEnter: () => {
				this.createVideoSrc(this.videoSrc)
				this.video.load()
				this.onScrollToggleVideoState()
				this.canPlay = true
			}
		})
	}
  
	createVideoSrc(vid) {
		const src = vid.getAttribute('data-src')
		if (!src) {
			return
		}
		vid.src = src
	}

	onScrollToggleVideoState() {

		this.scrollTriggerState = ScrollTrigger.create({
			trigger: this.element,
			start: 'top bottom',
			end: 'bottom top',
			onEnter: () => { 
				if(this.canPlay) this.video.play()
			},
			onEnterBack: () => { 
				if(this.canPlay) this.video.play()
			},
			onLeave: () => { 
				if(this.canPlay) this.video.pause()
			},
			onLeaveBack: () => {
				if(this.canPlay) this.video.pause()
			}
		})
	}

	unmount() {
		if(this.scrollTriggerLoader) {
			this.scrollTriggerLoader.kill()
		}

		if(this.scrollTriggerState) {
			this.scrollTriggerState.kill()
		}
	}
}