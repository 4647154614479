import SplitTitlesToLines from '../helpers/SplitTitlesToLines'

export default class ContactBlock {
	constructor(el, index) {
		this.element 				= el
		this.title					= this.element.querySelector('.c-ContactBlock__title p')
		this.index 					= index

		this.splitTitleHelper 		= null
	}
  
	init() {

		this.splitTitleHelper = new SplitTitlesToLines(this.title)
	}

	unmount() {

		this.splitTitleHelper.destroy()
	}
}
