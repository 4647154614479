import GSAP from 'gsap'
import { SplitText } from 'gsap/SplitText'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { Animations } from '../helpers/Animations'

GSAP.registerPlugin(SplitText, ScrollTrigger)

export default class Footer {
	constructor(el, index) {
		this.element 			= el
		this.title 				= this.element.querySelector('.c-Footer__title')
		this.index 				= index

		this.splitParent		= null
		this.splitChild 		= null

		this.tl 				= GSAP.timeline({ paused: true })
		this.STInstance 		= null
		this.STFooterInstance 	= null
		this.last 				= document.querySelector('.main').lastElementChild

		this.handleEmitterState = () => {
			this.STFooterInstance.refresh()
		}
	}
  
	init() {
		this.setup()
		this.animateTitle()
		this.createScrolltrigger()

		window.emitter.on('accordionTriggered', this.handleEmitterState)
	}

	createScrolltrigger() {

		if(document.body.classList.contains('page-template-page-thankyou') || document.body.classList.contains('error404')) {

			setTimeout(() => {
				this.STFooterInstance = ScrollTrigger.create({
					trigger: 'main',
					start: 'bottom bottom',
					endTrigger: this.element,
					end: 'bottom bottom',
					pinSpacing: false,
					pin: 'main',
					scrub: 1,
					invalidateOnRefresh: true
				})
			}, 400)

		} else {

			setTimeout(() => {
			
				this.STFooterInstance = ScrollTrigger.create({
					trigger: this.last,
					start: 'bottom bottom',
					endTrigger: this.element,
					end: 'bottom bottom',
					pinSpacing: false,
					pin: this.last,
					scrub: 1,
					invalidateOnRefresh: true
				})
			}, 400)
		}
	}
  
	setup() {
		this.splitParent = new SplitText(this.title, {
			type: 'words',
			wordsClass: "parent"
		})

		this.splitChild = new SplitText(this.title, {
			type: 'words',
			wordsClass: "child"
		})
	}

	animateTitle() {

		this.tl.to(this.splitChild.words, {
			y: 0,
			ease: Animations.ease.generic,
			duration: Animations.timing.titles,
			stagger: Animations.stagger.titles,
		})

		this.STInstance = ScrollTrigger.create({
			trigger: this.title,
			start: "bottom bottom",
			onEnter: () => {
				this.tl.play()
			},
			once: true
		})
	}

	unmount() {

		if(this.splitChild) { 
			this.splitParent.revert()
			this.splitChild.revert()
		}

		if(this.STInstance) {
			this.STInstance.kill
			this.tl.kill()
		}

		if(this.STFooterInstance) {
			this.STFooterInstance.kill()
		}

		window.emitter.off('accordionTriggered', this.handleEmitterState)
	}
}
