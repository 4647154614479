import Flickity from 'flickity'
import SplitTitlesToLines from '../helpers/SplitTitlesToLines'

export default class SliderSmall {
	constructor(el, index) {
		this.element 	= el
		this.cellWrap 	= this.element.querySelector('.c-SliderSmall__cells')
		this.nextBtn 	= this.element.querySelector('.c-SliderSmall__control-next')
		this.prevBtn 	= this.element.querySelector('.c-SliderSmall__control-prev')
		this.text		= this.element.querySelector('.c-SliderSmall__paragraph')
		
		this.index 		= index

		this.handleNextClick 	= this.handleNextClick.bind(this)
		this.handlePrevClick 	= this.handlePrevClick.bind(this)

		this.splitTitleHelper 	= null
	}
  
	init() {

		this.flkty = new Flickity(this.cellWrap, {
			contain: true,
			groupCells: true,
			pageDots: false,
			prevNextButtons: false,
			resize: true,
			draggable: true,
			cellAlign: 'left',
			adaptiveHeight: false,
		})

		this.nextBtn.addEventListener('click', this.handleNextClick)
		this.prevBtn.addEventListener('click', this.handlePrevClick)

		this.splitTitleHelper = new SplitTitlesToLines(this.text)
	}

	handleNextClick() {
		this.flkty.next()
	}

	handlePrevClick() {
		if(this.flkty.selectedIndex !== 0) {
			this.flkty.previous()
		}
	}

	unmount() {
		this.flkty.destroy()

		this.nextBtn.removeEventListener('click', this.handleNextClick)
		this.prevBtn.removeEventListener('click', this.handlePrevClick)

		this.splitTitleHelper.destroy()
	}
}
