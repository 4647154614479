import GSAP from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/SplitText'
import { Animations } from './Animations'
import { Utils } from './Utils'

GSAP.registerPlugin(ScrollTrigger, SplitText)

export default class SplitTitlesToLines {
	constructor(target) {
		this.title 			= target

		this.splitChild 	= null
		this.splitParent	= null
		this.canResize 		= true
		this.handleResize 	= Utils.debounceResize(this.handleResize.bind(this), 300)

		this.init()
	}

	init() {

		if(!window.isTouchscreen) {
			window.addEventListener('resize', this.handleResize)
		}

		setTimeout(() => {
			this.splitTitle()
			this.setupAnimation()
		}, 300)
	}

	splitTitle() {
		this.splitChild = new SplitText(this.title, {
			type: 'lines',
			linesClass: 'child'
		})

		this.splitParent = new SplitText(this.title, {
			type: 'lines',
			linesClass: 'parent'
		})
	}

	handleResize() {

		if(this.canResize) {
			this.splitParent.revert()
			this.splitChild.revert()

			this.splitChild = new SplitText(this.title, {
				type: 'lines',
				linesClass: 'child'
			})
	
			this.splitParent = new SplitText(this.title, {
				type: 'lines',
				linesClass: 'parent'
			})

			this.tl.kill()
			this.STInstance.kill()

			this.setupAnimation()
		}
	}

	setupAnimation() {

		this.tl	= GSAP.timeline({ paused: true })

		this.tl.to(this.splitChild.lines, {
			y: 0,
			ease: Animations.ease.generic,
			duration: Animations.timing.titles,
			stagger: Animations.stagger.titles,
			onComplete: () => { 

				if(this.splitChild)  {
					this.canResize = false
					this.splitParent.revert()
					this.splitChild.revert()
					if(!window.isTouchscreen) {
						window.removeEventListener('resize', this.handleResize)
					}
				}
			}
		})

		this.STInstance = ScrollTrigger.create({
			trigger: this.title,
			start: 'top bottom-=200px',
			once: true,
			onEnter: () => {
				this.tl.play()
			}
		})
	}

	destroy() {
		if(this.tl) {
			this.tl.kill()
		}

		if(this.STInstance) {
			this.STInstance.kill()
		}

		if(this.splitParent) {
			this.splitParent.revert()
			this.splitChild.revert()
		}

		if(this.canResize && !window.isTouchscreen) {
			window.removeEventListener('resize', this.handleResize)
		}

		this.canResize 		= true
	}
}