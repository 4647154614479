import SplitTitlesToLines from '../helpers/SplitTitlesToLines'

export default class LargeParagraph {
	constructor(el, index) {
		this.element 	= el
		this.largeText 	= this.element.querySelector('.c-LargeParagraph__text__large')
		this.smallText 	= this.element.querySelector('.c-LargeParagraph__text__small')
		this.index 		= index

		this.splitTitleLarge 	= null
		this.splitTitleSmall 	= null
	}
  
	init() {

		this.splitTitleLarge = new SplitTitlesToLines(this.largeText)
		this.splitTitleSmall = new SplitTitlesToLines(this.smallText)
	}
  
	unmount() {

		this.splitTitleLarge.destroy()
		this.splitTitleSmall.destroy()
	}
}
