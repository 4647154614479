import GSAP from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { Utils } from '../helpers/Utils'

GSAP.registerPlugin(ScrollTrigger)

export default class ScrollToContent {
	constructor(el, index) {
		this.element 		= el
		this.links 			= this.element.querySelectorAll('.c-ScrollToContent__links a')
		this.dataBlock		= this.element.querySelectorAll('.c-ScrollToContent__content__block')
		this.index 			= index

		this.offsetValue 	= 95

		this.STInstances 	= []
		this.offsets		= []
		this.eventListeners = []

		this.handleResize 	= Utils.debounceResize(this.handleResize.bind(this), 300)

		this.handleEmitterState = () => {

			this.STInstances.forEach(instance => {
				instance.refresh()
			})

			setTimeout(() => {
				this.getOffsets()
			}, 100)//buffer
		}
	}

	init() {

		setTimeout(() => {
			this.getOffsets()
		}, 1000)

		this.updateLinks()
		this.animateToSection()

		window.addEventListener('resize', this.handleResize)
		window.emitter.on('accordionTriggered', this.handleEmitterState)
	}

	updateLinks() {

		this.links[0].classList.add('active')

		this.dataBlock.forEach((block, idx) => {

			const STInstance = ScrollTrigger.create({
				trigger: block,
				start: 'top top+=140px',
				end: 'bottom top+=140px',
				onEnter: () => {
					this.links.forEach(link => {
						link.classList.remove('active')
					})
					this.links[idx].classList.add('active')
				},
				onEnterBack: () => {
					this.links.forEach(link => {
						link.classList.remove('active')
					})
					this.links[idx].classList.add('active')
				}
			})

			this.STInstances.push(STInstance)
		})
	}

	getOffsets() {
		this.offsets = []

		this.dataBlock.forEach(block => {
			const blockOffset = block.offsetTop
			this.offsets.push(Math.round(blockOffset - this.offsetValue))
		})

		// console.log(this.offsets)
	}

	animateToSection() {

		this.links.forEach((link, idx) => {


			const listener = (e) => {
				e.preventDefault()

				window.scroll({
					top: this.offsets[idx],
					behavior: 'smooth'
				})
			}

			link.addEventListener('click', listener)
			this.eventListeners.push({ link, listener })
		})
	}

	handleResize() {
		this.getOffsets()
	}

	unmount() {
		this.STInstances.forEach(instance => {
			instance.kill()
		})

		this.offsets = []

		this.eventListeners.forEach(({ link, listener }) => {
			link.removeEventListener('click', listener)
		})

		window.removeEventListener('resize', this.handleResize.bind(this))
		window.emitter.off('accordionTriggered', this.handleEmitterState)
	}
}
