import GSAP from 'gsap'
import { Animations } from '../helpers/Animations'

export default class Button {
	constructor(el, index) {
		this.element 		= el
		this.fill 			= this.element.querySelector('.c-Button__fill')
		this.buttonText 	= this.element.querySelector('.c-Button__text')
		this.index 			= index

		this.mouseEnterHandler = this.mouseEnterHandler.bind(this)
		this.mouseLeaveHandler = this.mouseLeaveHandler.bind(this)
	}
  
	init() {
		
		if(!window.isTouchscreen) {
			this.element.addEventListener('mouseenter', this.mouseEnterHandler)
			this.element.addEventListener('mouseleave', this.mouseLeaveHandler)
		}
	}

	mouseEnterHandler() {

		GSAP.set(this.fill, {
			y: '80%',
		})
		GSAP.set(this.buttonText, {
			yPercent: 0,
		})

		GSAP.to(this.fill, {
			y: 0,
			ease: Animations.ease.powerTwo,
			duration: Animations.timing.button,
			overwrite: true
		})
		GSAP.to(this.buttonText, {
			yPercent: -100,
			ease: Animations.ease.powerTwo,
			duration: Animations.timing.button,
			overwrite: true,
			onComplete: () => {
				GSAP.set(this.buttonText, {
					yPercent: 0,
				})
			}
		})
	}

	mouseLeaveHandler() {
		GSAP.to(this.fill, {
			y: '-80%',
			ease: Animations.ease.sin,
			duration: Animations.timing.button,
			overwrite: true
		})
		GSAP.to(this.buttonText, {
			yPercent: -100,
			ease: Animations.ease.powerTwo,
			duration: Animations.timing.button,
			overwrite: true
		})
	}

	unmount() {

		if(!window.isTouchscreen) {
			this.element.removeEventListener('mouseenter', this.mouseEnterHandler)
			this.element.removeEventListener('mouseleave', this.mouseLeaveHandler)
		}
	}
}
