import 'lazysizes'
import barba from '@barba/core'
import GSAP from 'gsap'
import mitt from 'mitt'
import { Utils } from './helpers/Utils'

// Create an emitter instance
window.emitter = mitt()
window.barbaRef = barba
window.isTouchscreen = Utils.isTouchDevice()

// console.log(window.isTouchscreen)

// GLOBAL HELPERS
import SmoothScroll from './helpers/SmoothScroll'
import BarbaBodyClass from './helpers/BarbaBodyClass'

// REF SMOOTH SCROLL
let smoothScroll

let preloaderRef
import Preloader from './components/c-Preloader'

import PageTransition from './components/c-PageTransition'
let pageTransitionRef = null

// GLOBAL COMPONENTS
import Marquee from './components/c-Marquee'
import MediaBlock from './components/c-MediaBlock'
import LargeParagraph from './components/c-LargeParagraph'
import NavigationBar from './components/c-NavigationBar'
import ContentBlock from './components/c-ContentBlock'
import NavToggler from './components/c-NavToggler'
import PageTitle from './components/c-PageTitle'
import Video from './components/c-Video'
import Slider from './components/c-Slider'
import SliderSmall from './components/c-SliderSmall'
import Footer from './components/c-Footer'
import PartnerExperience from './components/c-PartnerExperience'
import CTAParallax from './components/c-CTAParallax'
import RichContent from './components/c-RichContent'
import ServiceGrid from './components/c-ServiceGrid'
import Accordion from './components/c-Accordion'
import ScrollToContent from './components/c-ScrollToContent'
import Hero from './components/c-Hero'
import Button from './components/c-Button'
import Menu from './components/c-Menu'
import SimpleContent from './components/c-SimpleContent'
import CTAMedia from './components/c-CTAMedia'
import ContactBlock from './components/c-ContactBlock'
import FullwidthImage from './components/c-FullwidthImage'
import PackagesGrid from './components/c-PackagesGrid'

// ARRAY FOR HANDLING COMPONENTS
let scriptRefs = []
  
// string must appear as it does in the PHP class file
const componentMap = {
	'marquee': Marquee,
	'mediaBlock': MediaBlock,
	'largeParagraph': LargeParagraph,
	'navigationBar': NavigationBar,
	'contentBlock': ContentBlock,
	'navToggler': NavToggler,
	'pageTitle': PageTitle,
	'video': Video,
	'slider': Slider,
	'sliderSmall': SliderSmall,
	'footer': Footer,
	'partnerExperience': PartnerExperience,
	'ctaParallax': CTAParallax,
	'richContent': RichContent,
	'serviceGrid': ServiceGrid,
	'accordion': Accordion,
	'scrollToContent': ScrollToContent,
	'hero': Hero,
	'button': Button,
	'menu': Menu,
	'simpleContent': SimpleContent,
	'ctaMedia': CTAMedia,
	'contactBlock': ContactBlock,
	'fullwidthImage': FullwidthImage,
	'packagesGrid': PackagesGrid
}

if (history.scrollRestoration) {
	history.scrollRestoration = 'manual'
}

const App = {

	init: () => {

		preloaderRef 		= new Preloader(document.querySelector('.c-Preloader'))
		pageTransitionRef 	= new PageTransition(document.querySelector('.c-PageTransition'))

		setTimeout(() => {
			window.scroll({ top: 0, behavior: 'instant' })
		}, 100)

		if(!window.isTouchscreen) {
			smoothScroll = new SmoothScroll()
		}

		scriptRefs = [] // Clear existing scriptRefs, if any

		Object.keys(componentMap).forEach(componentName => {
			const componentElements = document.querySelectorAll(`[data-component-type="${componentName}"]`)
			componentElements.forEach((el, index) => {
				scriptRefs.push(new componentMap[componentName](el, index))
				scriptRefs[scriptRefs.length - 1].init()
			})
		})

	},

	initOnNewPage: (data) => {

		return new Promise((resolve) => {

			scriptRefs = [] // Clear existing scriptRefs

			// Get the components present in the new page
			const newComponents = Array.from(data.next.container.querySelectorAll('[data-component-type]')).map(el => el.dataset.componentType)

			// Filter out duplicate components and initialize them
			const uniqueComponentTypes = Array.from(new Set(newComponents))

			uniqueComponentTypes.forEach(componentName => {
				const componentElements = data.next.container.querySelectorAll(`[data-component-type="${componentName}"]`)
				componentElements.forEach((el, index) => {
					if (componentMap[componentName]) {
						scriptRefs.push(new componentMap[componentName](el, index))
						scriptRefs[scriptRefs.length - 1].init()

						//console.log(`Initialized component ${componentName}`)
					}
				})
			})

			resolve()
		})
	},

	createBarba: () => {
		let bch = new BarbaBodyClass()

		window.barbaRef.init({
			sync: true,
			transitions: [{
				name: 'default-transition',
				once: () => {

					preloaderRef.loadAnimation()

				},
				before: async (data) => {

					await new Promise(resolve => {
						pageTransitionRef.show(resolve)
					})
					
				},
				leave: () => {

				},
				beforeEnter: async (data) => {
		
					if(!window.isTouchscreen) {
						smoothScroll.scrollToTop(0)
					} else {
						setTimeout(() => {
							window.scroll({ top: 0, behavior: 'instant' })
						}, 10)
					}

					await new Promise(resolve => {
						scriptRefs.forEach(component => {
							if (data.current.container.contains(component.element)) {
								component.unmount()
								// console.log('unmonting', component)
							}
						})
						resolve()
					})
		
					bch.getClasses(data)
				},
				after: async (data) => {

					await App.initOnNewPage(data)

					pageTransitionRef.hide()
				}
			}]
		})
	}
}

document.addEventListener('DOMContentLoaded', () => {
	App.init()
	App.createBarba()
})
