import GSAP from 'gsap'
import SplitTitlesToLines from '../helpers/SplitTitlesToLines'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { Animations } from '../helpers/Animations'
import { Utils } from '../helpers/Utils'

GSAP.registerPlugin(ScrollTrigger)


export default class ServiceGrid {
	constructor(el, index) {
		this.element 				= el
		this.media					= [...this.element.querySelectorAll('.c-ServiceItem')]
		this.text					= this.element.querySelector('.c-ServiceGrid__header__text')
		this.index 					= index

		this.splitTitleHelper 		= null

		this.handleResize 			= this.handleResize.bind(this)
		this.handleDebouncedResize 	= Utils.debounceResize(this.handleResize, 200)
	}
  
	init() {

		this.splitTitleHelper = new SplitTitlesToLines(this.text)

		this.getDelayValues()

		const animations = this.media.map((item, idx) => {

			const mask = item.querySelector('.c-ServiceItem__media__background')

			return new Promise((resolve) => {
				this.STInstance = ScrollTrigger.create({
					trigger: item,
					start: 'top center',
					once: true,
					onEnter: () => {
						GSAP.to(mask, {
							duration: Animations.timing.clipPath,
							ease: Animations.ease.circ,
							delay: this.delay[idx],
							yPercent: -101,
							onStart: () => item.classList.add('in-view'),
							onComplete: () => {
								resolve()
							}
						})
					},
				})
			})
		})

		Promise.all(animations).then(() => {
			this.removeResizeListener()
		})

		window.addEventListener('resize', this.handleDebouncedResize)
	}

	removeResizeListener() {
		window.removeEventListener('resize', this.handleDebouncedResize)
	}

	handleResize() {
		this.getDelayValues()
	}

	getDelayValues() {

		this.delay = this.media.map((item, idx) => {
			if (window.innerWidth > 1023) {
				return (idx % 3) * 0.2
			} else if (window.innerWidth > 767) {
				return (idx % 2) * 0.2
			} else {
				return 0
			}
		})

		// console.log(this.delay)
	}

	unmount() {
		this.splitTitleHelper.destroy()

		if(this.STInstance) {
			this.STInstance.kill()
		}

		this.removeResizeListener()
	}
}
