import GSAP from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

GSAP.registerPlugin(ScrollTrigger)

export default class Hero {
	constructor(el, index) {
		this.element 			= el
		this.record 			= this.element.querySelector('img')
		this.recordContainer	= this.element.querySelector('.c-Hero__container__image')
		this.logo				= this.element.querySelector('.c-Hero__logo')
		this.button 			= this.element.querySelector('.c-Hero__button')

		this.index 				= index

		this.spinTL 			= null
		this.scrollTL			= null
		this.logoTL 			= null
		this.STInstance 		= null

		this.vh 				= null

		this.handleClick 		= this.handleClick.bind(this)
	}
  
	init() {
		this.setup()
		this.playRecord()
		this.setupScrollTrigger()

		this.button.addEventListener('click', this.handleClick)

		if(window.isTouchscreen) {
			this.setElementHeight()
		}
	}

	setElementHeight() {
		this.vh = window.innerHeight * 0.01
		document.documentElement.style.setProperty('--vh', `${this.vh}px`)
	}

	handleClick() {

		window.scroll({
			top: window.innerHeight,
			behavior: 'smooth'
		})
	}

	setupScrollTrigger() {
		this.scrollTL = GSAP.timeline({ paused: true })

		this.scrollTL.to(this.recordContainer, {
			scale: 1.2,
			scrollTrigger: {
				trigger: this.element,
				start: 'top top',
				end: 'bottom top',
				scrub: 1
			}
		})

		this.STInstance = ScrollTrigger.create({
			trigger: this.element,
			start: 'top top',
			end: 'bottom top',
			onEnter: () => this.playRecord(),
			onEnterBack: () => this.playRecord(),
			onLeave: () => this.pauseRecord()
		})
	}

	setup() {
		this.spinTL = GSAP.timeline({ repeat: -1, paused: true})

		this.spinTL.to(this.record, {
			rotate: 360,
			duration: 2,
			ease: 'linear'
		})

		this.logoTL = GSAP.timeline({ repeat: -1, paused: true})

		this.logoTL.to(this.logo, {
			y:  '-50%',
			duration: 40,
			ease: 'linear'
		})

	}

	playRecord() {
		this.spinTL.play()
		this.logoTL.play()
	}

	pauseRecord() {
		this.spinTL.pause()
		this.logoTL.pause()
	}

	unmount() {

		this.button.removeEventListener('click', this.handleClick)

		if(this.spinTL) {
			this.spinTL.kill()
		}

		if(this.logoTL) {
			this.logoTL.kill()
		}

		if(this.scrollTL) {
			this.scrollTL.kill()
		}

		if(this.STInstance) {
			this.STInstance.kill()
		}
	}
}
