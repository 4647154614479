import GSAP from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import SplitTitlesToLines from '../helpers/SplitTitlesToLines'

GSAP.registerPlugin(ScrollTrigger)

export default class CTAParallax {
	constructor(el, index) {
		this.element 			= el
		this.images 			= this.element.querySelectorAll('figure')
		this.title				= this.element.querySelector('h2')
		this.index 				= index

		this.tl 				= null
		this.splitTitleHelper 	= null
	}
  
	init() {

		this.animate()
		this.splitTitleHelper = new SplitTitlesToLines(this.title)
	}

	animate() {

		this.images.forEach((img, idx) => {

			this.tl = GSAP.timeline()

			this.tl.to(img, {
				y: () =>  idx == 0 ? -(img.offsetHeight * 2) : -img.offsetHeight,
				rotate: idx == 0 ? -14 : 6,
				scrollTrigger: {
					trigger: this.element,
					start: 'top bottom',
					end: 'bottom top',
					scrub: idx == 0 ? 2 : 1,
				}
			})
		})

	}
  
	unmount() {
		if(this.tl) {
			this.tl.kill()
			this.tl = null
		}

		this.splitTitleHelper.destroy()
	}
}
