import GSAP from 'gsap'

export default class NavToggler {
	constructor(el, index) {
		this.element 	= el
		this.index 		= index

		this.toggled 	= false

		this.navToggleHandler = (e) => { 

			this.toggled = !this.toggled

			this.handleStateChange()
		}
	}
  
	init() {

		window.emitter.on('pageReady', this.navToggleHandler)
	}

	handleStateChange() {
		if(this.toggled === true) {
			this.show()
		} else {
			this.hide()
		}
	}

	show() {
		GSAP.to(this.element, { opacity: 1 })
	}

	hide() {
		GSAP.to(this.element, { opacity: 0 })
	}

	unmount() {
		console.log('unmounted navToggler')
		window.emitter.off('pageReady', this.navToggleHandler)
	}
}
