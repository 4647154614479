import GSAP from 'gsap'

export default class PageTransition {
	constructor(el, index) {
		this.element 	= el
		this.index 		= index

		this.panels 	= this.element.querySelectorAll('.c-PageTransition__panel')

		this.params = {
			light: '#F3F2EF',
			dark: '#798591'
		}

		this.init()
	}
  
	init() {

		GSAP.set(this.element, {
			y: '100%'
		})
	}

	show(resolve) {

		this.getDataAttribute()

		const TL = GSAP.timeline()

		TL.set(this.element, {
			y: 0
		})

		TL.set(this.panels, {
			yPercent: 100,
		})

		TL.to(this.panels, {
			yPercent: 0,
			duration: 1,
			ease: 'circ.inOut',
			stagger: 0.2,
			onComplete: () => resolve()
		}, '<')
	}

	hide() {

		const TL = GSAP.timeline()

		TL.to(this.panels, {
			delay: 0.5,
			yPercent: -100,
			ease: 'circ.inOut',
			duration: 1,
			onComplete: () => {
				window.emitter.emit('pageReady')

				GSAP.set(this.element, {
					y: '100%'
				})
				GSAP.set(this.panels, {
					yPercent: 100,
				})
			}
		})
	}

	getDataAttribute() {
		this.mainTag 	= document.querySelector('main')
		this.data 		= this.mainTag.getAttribute('data-theme')

		if (this.data === '') {
			// Handle the case where data-theme is an empty string
			this.setColourValue(this.params.light)
		} else {
			for (const key in this.params) {
				if (key === this.data) {
					this.setColourValue(this.params[key])
				}
			}
		}
	}

	setColourValue(value) {
		this.panels[0].style.backgroundColor = value
	}

	unmount() {
		
	}
}
