import GSAP from 'gsap'
import SplitTitlesToLines from '../helpers/SplitTitlesToLines'

export default class ContentBlock {
	constructor(el, index) {
		this.element 				= el
		this.title 					= this.element.querySelector('.c-ContentBlockItem__title')
		this.mediaWrapper 			= this.element.querySelector('.c-ContentBlockItem__wrapper__media')
		this.media					= this.element.querySelector('.c-ContentBlockItem__wrapper__media img')
		this.index 					= index

		this.dataOptions			= this.element.getAttribute('data-options')
		this.parsedData 			= JSON.parse(this.dataOptions)

		this.splitTitleHelper 		= null
		this.offsetDistance 		= null
		this.scrollTriggerInstance 	= null

		this.mm 					= GSAP.matchMedia()
	}
  
	init() {

		if(this.parsedData.hasParallax == 'true') {

			// this.mm.add("(min-width: 1024px)", () => {

				this.offsetDistance = this.media.offsetHeight - this.mediaWrapper.offsetHeight

				GSAP.set(this.media, {
					y: this.offsetDistance * 0.5,
				})
	
				this.scrollTriggerInstance = GSAP.to(this.media, {
					y: () => -this.offsetDistance * 0.5,
					ease: 'none',
					scrollTrigger: {
						trigger: this.mediaWrapper,
						start: 'top bottom',
						end: 'bottom top',
						scrub: 1.5,
						invalidateOnRefresh: true
					}
				})

				// console.log('bigger')

			// 	return () => {
			// 		// console.log('smaller')
			// 		this.scrollTriggerInstance.kill()
			// 	}
			// })
		}

		this.splitTitleHelper = new SplitTitlesToLines(this.title)
	}

	unmount() {
		if(this.scrollTriggerInstance) {
			this.scrollTriggerInstance.kill()
			this.mm = null
		}

		this.splitTitleHelper.destroy()
	}
}
