import GSAP from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

GSAP.registerPlugin(ScrollTrigger)

export default class Marquee {
	constructor(el, index) {
		this.element 	= el
		this.title 		= this.element.querySelectorAll('.c-Marquee__title')
		this.index 		= index

		this.dataOptions			= this.element.getAttribute('data-speed')
		this.parsedData 			= JSON.parse(this.dataOptions)
	}
  
	init() {

		this.tl = GSAP.timeline({ repeat: -1, paused: true })

		this.tl.to(this.title, {
			duration: this.parsedData.speed,
			ease: 'none',
			xPercent: () => -100
		})

		this.scrolltriggerInstance = ScrollTrigger.create({
			trigger: this.element,
			start: 'top bottom',
			end: 'bottom top',
			onEnter: () => this.tl.play(),
			onEnterBack: () => this.tl.play(),
			onLeave: () => this.tl.pause(),
			onLeaveBack: () => this.tl.pause()
		})
	}

	unmount() {

		if(this.scrolltriggerInstance) {
			this.scrolltriggerInstance.kill()
		}
			
		if(this.tl) { 
			this.tl.kill()
		}
	}
}
