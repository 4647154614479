import GSAP from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

GSAP.registerPlugin(ScrollTrigger)

export default class PartnerExperience {
	constructor(el) {
		this.element = el
		this.imageWrapper 	= this.element.querySelector('.c-PartnerExperience__images')
		this.title 			= this.element.querySelector('h3')
		this.graphic 		= this.element.querySelector('.graphic')
		this.images 		= [...this.element.querySelectorAll('img')]

		this.STInstance = null

		this.progress = {
			num: 0
		}
	}

	init() {

		this.tl = GSAP.timeline({paused: true})

		this.tl.to(this.imageWrapper, {
			y: 500,
			ease:"none",
		})

		this.tl.to(this.progress, {
			num: this.images.length - 1,
			ease:"none",
			onUpdate: () => { 
				this.showImage(this.progress.num.toFixed())
			}
		}, '<')

		this.STInstance = ScrollTrigger.create({
			animation: this.tl,
			trigger: this.title,
			start: 'top bottom-=200px',
			end: 'bottom top',
			scrub: 1,
			invalidateOnRefresh: true
		})
		
	}

	showImage(num) {
		if(this.images[num]) {
			GSAP.set(this.images, {
				opacity: 0
			})
			GSAP.set(this.images[num], {
				opacity: 1
			})
			// GSAP.to(this.graphic, {
			// 	rotate: '+=180'
			// })
		}
	}

	unmount() {
		if(this.STInstance) {
			this.STInstance.kill()
		}

		if(this.tl) {
			this.tl.kill()
		}
	}
}