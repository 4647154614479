import GSAP from 'gsap'
import { Animations } from '../helpers/Animations'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

GSAP.registerPlugin(ScrollTrigger)

export default class PackagesGrid {
	constructor(el, index) {
		this.element 		= el
		this.gridItems 		= [...this.element.querySelectorAll('.c-PackageItem')]
		this.index 			= index

		this.STInstance 	= null
	}
  
	init() {
		
		if(this.gridItems) {

			this.setup()
		}
	}

	setup() {

		GSAP.set(this.gridItems, {
			y: 100,
			opacity: 0
		})

		this.STInstance = ScrollTrigger.batch(this.gridItems, {
			start: 'top 70%',
			once: true,
			onEnter: (elements) => {
				  GSAP.to(elements, { 
					y: 0,
					opacity: 1,
					ease: Animations.ease.generic,
					duration: Animations.timing.gridItem,
					stagger: Animations.stagger.gridItem
				})
			}
		})

	}

	unmount() {

		if(this.STInstance) {
			this.STInstance.forEach(instance => {
				instance.kill()
			})
		}
	}
}
