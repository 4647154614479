import GSAP from 'gsap'

export default class FullwidthImage {
	constructor(el, index) {
		this.element 			= el
		this.index 				= index
		this.imageWrapper 		= this.element.querySelector('.c-FullwidthImage__wrapper')
		this.image				= this.element.querySelector('img')
	}
  
	init() {

		this.tl = GSAP.timeline({
			scrollTrigger: {
				trigger: this.element,
				start: 'top top',
				end: 'bottom top',
				scrub: 1
			}
		})

		this.tl.to(this.image, {
			scale: 1.3
		})
	}


	unmount() {
		this.tl.kill()
	}
}
