import SplitTitlesToLines from '../helpers/SplitTitlesToLines'

export default class SimpleContent {
	constructor(el, index) {
		this.element 			= el
		this.text				= this.element.querySelector('.c-SimpleContent__title h2')
		this.index 				= index

		this.splitTitleHelper 	= null
	}
  
	init() {

		this.splitTitleHelper = new SplitTitlesToLines(this.text)
	}

	unmount() {
		this.splitTitleHelper.destroy()
	}
}
