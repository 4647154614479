import GSAP from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import SplitTitlesToLines from '../helpers/SplitTitlesToLines'

GSAP.registerPlugin(ScrollTrigger)

export default class NavToggler {
	constructor(el, index) {
		this.element 			= el
		this.index 				= index
		this.images				= [...this.element.querySelectorAll('img')]
		this.mobImagery 		= [...this.element.querySelectorAll('.c-RichContent__content__media img')]
		this.title				= this.element.querySelector('h2')

		this.tl 				= null
		this.splitTitleHelper 	= null

		this.mm 				= GSAP.matchMedia()
	}
  
	init() {

		this.splitTitleHelper = new SplitTitlesToLines(this.title)
		this.createParallax()
	}

	createParallax() {

		this.mm.add({
			isDesktop: `(min-width: 1024px)`,
			isMobile: `(max-width: 1023px)`
		}, (context) => {
			let { isDesktop, isMobile } = context.conditions

				if(isDesktop) {
					this.images.forEach((img, idx) => {

						this.deskTL = GSAP.timeline()

						this.deskTL.to(img, {
							y: () => idx == 1 ? -img.offsetHeight * 0.5 : -(img.offsetHeight * 0.7),
							scrollTrigger: {
								trigger: this.element,
								start: 'top bottom',
								end: 'bottom top',
								scrub: idx == 0 ? 0.6 : 1.2,
								invalidateOnRefresh: true
							}
						})
					})
				}

				if(isMobile) {

					this.mobImagery.forEach(img => {
						GSAP.set(img, {
							y: img.offsetHeight
						})
					})

					this.mobImagery.forEach((img, idx) => {

						this.mobTL = GSAP.timeline()

						this.mobTL.to(img, {
							y: () => idx == 0 ? 0 : -(img.offsetHeight * 0.4),
							scrollTrigger: {
								trigger: '.c-RichContent__content__media',
								start: 'top bottom',
								end: 'bottom top',
								scrub: idx == 0 ? 0.6 : 1.2,
								invalidateOnRefresh: true
							}
						})
					})

				}

			return () => { 
				if(!isDesktop) {
					//console.log('isDesk')

					if(this.mobTL) this.mobTL.kill()

					this.mobImagery.forEach(img => {
						GSAP.set(img, {
							clearProps: true
						})
					})
				}

				if(!isMobile) {
					//console.log('isMob')

					if(this.deskTL) this.deskTL.kill()

					this.images.forEach(img => {
						GSAP.set(img, {
							clearProps: true
						})
					})
				}
			}
		})

	}

	unmount() {
		if(this.deskTL) this.deskTL.kill()
		if(this.mobTL) this.mobTL.kill()

		this.mm = null

		this.splitTitleHelper.destroy()
	}
}
