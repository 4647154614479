import GSAP from 'gsap'
import lottie from 'lottie-web'
import animationDataPath from '../data/data.json'
import { Animations } from '../helpers/Animations'

export default class Preloader {
	constructor(el) {
		this.element 		= el
		this.container		= this.element.querySelector('.c-Preloader__container')
		this.animationData	= animationDataPath
		this.animation		= null

		this.animationCompletePromise = null
	}

	loadAnimation() {

		if (!this.container) {
			console.error(`No container found.`)
			return
		}

		const animationOptions = {
			container: this.container,
			renderer: 'svg',
			loop: false,
			autoplay: true,
			animationData: this.animationData
		}

		this.animation = lottie.loadAnimation(animationOptions)

		this.animation.addEventListener('complete', () => {
			this.reveal()
		})
	}

	reveal() {
		GSAP.to(this.element, {
			clipPath: 'inset(0% 0% 100% 0%)',
			duration: Animations.timing.clipPath,
			ease: Animations.ease.circ,
			onComplete: () => {
				window.emitter.emit('pageReady')
				setTimeout(() => {
					this.hide()
				}, 3000)
			}
		})
	}

	hide() {
		GSAP.set(this.element, {
			y: '-100%',
			onComplete: () => {
				this.element.remove()
			}
		})
	}
}
