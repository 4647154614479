import GSAP from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

GSAP.registerPlugin(ScrollTrigger)

export default class MediaBlock {
	constructor(el) {
		this.element = el

		this.imageWrapper = this.element.querySelector('.c-MediaBlock__media')
		this.image = this.element.querySelector('img')
		this.offsetDistance = null
		this.scrollTriggerInstance = null
	}

	init() {

		this.offsetDistance = this.image.offsetHeight - this.imageWrapper.offsetHeight

		this.image.addEventListener('click', () => {
			window.emitter.emit('foo', { value: this.offsetDistance, string: 50 })
		})

		GSAP.set(this.image, {
			y: this.offsetDistance * 0.5,
		})

		this.scrollTriggerInstance = GSAP.to(this.image, {
			y: () => -this.offsetDistance * 0.5,
			ease: 'none',
			scrollTrigger: {
				trigger: this.imageWrapper,
				start: 'top bottom',
				end: 'bottom top',
				scrub: true,
				invalidateOnRefresh: true
			}
		})

		setTimeout(() => {
			ScrollTrigger.refresh()
		}, 100)
	}

	unmount() {
		this.scrollTriggerInstance.kill()
		this.scrollTriggerInstance = null
		this.offsetDistance = null
	}
}