import SplitTitlesToLines from '../helpers/SplitTitlesToLines'

export default class CTAMedia {
	constructor(el, index) {
		this.element 			= el
		this.title				= this.element.querySelector('.c-CTAMedia__content__title')
		this.index 				= index

		this.splitTitleHelper 	= null
	}
  
	init() {

		this.splitTitleHelper = new SplitTitlesToLines(this.title)
	}

	unmount() {

		this.splitTitleHelper.destroy()
	}
}
