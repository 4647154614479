import GSAP from 'gsap'

export default class Accordion {
	constructor(el, index) {
		this.element 		= el
		this.button 		= this.element.querySelector('.c-Accordion__header')
		this.contentPanel 	= this.element.querySelector('.c-Accordion__content')
		this.index 			= index

		this.isExpanded 	= false

		this.accordionHandler = (e) => { 
			this.isExpanded  = !this.isExpanded
			this.stateChangeHandler()
		}
	}
  
	init() {
		this.button.addEventListener('click', this.accordionHandler)
	}

	stateChangeHandler() {
		if(this.isExpanded === true) {
			this.open()
		} else {
			this.close()
		}
	}

	open() {
		GSAP.to(this.contentPanel, {
			height: 'auto',
			duration: 0.7,
			ease: 'power3.inOut',
			overwrite: true,
			onComplete: () => window.emitter.emit('accordionTriggered')
		})

		this.element.classList.add('active')
	}

	close() {
		GSAP.to(this.contentPanel, {
			height: 0,
			duration: 0.7,
			ease: 'power3.inOut',
        	overwrite: true,
			onComplete: () => window.emitter.emit('accordionTriggered')
		})

		this.element.classList.remove('active')
	}

	unmount() {
		this.button.removeEventListener('click', this.accordionHandler)
		this.isExpanded 	= false
	}
}
