import GSAP from 'gsap'
import { SplitText } from 'gsap/SplitText'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { Animations } from '../helpers/Animations'

GSAP.registerPlugin(SplitText, ScrollTrigger)

export default class PageTitle {
	constructor(el, index) {
		this.element 	= el
		this.text 		= this.element.querySelector('.c-PageTitle__text')
		this.index 		= index

		this.splitParent = null
		this.splitChild = null

		this.dataStyle 	= this.element.getAttribute('data-style')
		this.parsedData = JSON.parse(this.dataStyle)

		this.pageReadyHandler = (e) => {
			if (this.splitParent) {
				this.animate()
			}
		}
	}
  
	init() {
		setTimeout(() => { // needs a buffer for styles to load etc
			this.setup()
		}, 300)

		window.emitter.on('pageReady', this.pageReadyHandler)
	}
  
	setup() {

		if(this.parsedData.style == 'large') {

			this.splitParent = new SplitText(this.text, {
				type: 'words',
				wordsClass: "parent"
			})
	
			this.splitChild = new SplitText(this.text, {
				type: 'words',
				wordsClass: "child"
			})
		} 
		
		if(this.parsedData.style == 'small') {

			this.splitChild = new SplitText(this.text, {
				type: 'lines',
				linesClass: "child",
			})

			this.splitParent = new SplitText(this.text, {
				type: 'lines',
				linesClass: "parent",
			})
		}

	}

	animate() {
		if(this.parsedData.style == 'large') {
			GSAP.to(this.splitChild.words, {
				y: 0,
				ease: Animations.ease.generic,
				duration: Animations.timing.titles,
				stagger: Animations.stagger.titles,
				// onComplete: () => {
				// 	console.log('large done')
				// }
			})
		}
		
		if(this.parsedData.style == 'small') {
			GSAP.to(this.splitChild.lines, {
				y: 0,
				ease: Animations.ease.generic,
				duration: Animations.timing.titles,
				stagger: Animations.stagger.titles,
				onComplete: () => {
					// console.log('small done')
					if(this.splitParent) this.splitParent.revert()
					if(this.splitChild) this.splitChild.revert()
				}
			})
		}
	}
  
	unmount() {
		if(this.splitParent || this.splitChild) { 
			this.splitParent.revert()
			this.splitChild.revert()

			this.splitParent = null
			this.splitChild = null

			this.dataStyle 	= null
			this.parsedData = null
		}

		window.emitter.off('pageReady', this.pageReadyHandler)
	}
}