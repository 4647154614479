import Flickity from 'flickity'
import SplitTitlesToLines from '../helpers/SplitTitlesToLines'

export default class Slider {
	constructor(el, index) {
		this.element 			= el
		this.cellWrap 			= this.element.querySelector('.c-Slider__cells')
		this.nextBtn 			= this.element.querySelector('.c-Slider__control-next')
		this.prevBtn 			= this.element.querySelector('.c-Slider__control-prev')
		this.sliderTitle 		= this.element.querySelector('.c-Slider__paragraph')
		this.splitTitleHelper 	= null
		
		this.index 				= index

		this.handleNextClick 	= this.handleNextClick.bind(this)
		this.handlePrevClick 	= this.handlePrevClick.bind(this)
	}
  
	init() {

		this.flkty = new Flickity(this.cellWrap, {
			contain: true,
			pageDots: false,
			groupCells: true,
			initialIndex: 0,
			prevNextButtons: false,
			resize: true,
			draggable: true,
			cellAlign: 'left',
			adaptiveHeight: false,
			percentPosition: true,  
		})

		this.nextBtn.addEventListener('click', this.handleNextClick)
		this.prevBtn.addEventListener('click', this.handlePrevClick)
		

		this.splitTitleHelper = new SplitTitlesToLines(this.sliderTitle)
	}


	handleNextClick() {

		this.flkty.next()
	}

	handlePrevClick() {
		if(this.flkty.selectedIndex !== 0) {
			this.flkty.previous()
		}
	}

	unmount() {
		this.flkty.destroy()

		this.nextBtn.removeEventListener('click', this.handleNextClick)
		this.prevBtn.removeEventListener('click', this.handlePrevClick)

		this.splitTitleHelper.destroy()
	}
}
