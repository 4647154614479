import GSAP from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { Animations } from '../helpers/Animations'

GSAP.registerPlugin(ScrollTrigger)

export default class NavigationBar {
	constructor(el, index) {
		this.element 				= el
		this.index 					= index
		this.menuToggler 			= this.element.querySelector('.c-NavigationBar__toggler__btn')

		this.offset 				= 200
		this.scrollTriggerInstance 	= null

		this.handleClick 			= this.handleClick.bind(this)
	}
  
	init() {

		this.menuToggler.addEventListener('click', this.handleClick)


		// this.element.addEventListener('click', () => {
		// 	window.barbaRef.go('/')
		// })

		this.showAnim = GSAP.from(this.element, { 
			yPercent: -100,
			paused: true,
			ease: Animations.ease.powerTwo,
			duration: Animations.timing.navbar
		  }).progress(1)

		this.scrollTriggerInstance = ScrollTrigger.create({
			trigger: document.body,
			start: `top top-=${this.offset}`,
			onUpdate: (self) => {
				if(self.direction === -1) {
					this.showAnim.play()
					this.element.classList.add('active-back')
				} else {
					this.showAnim.reverse()
					this.element.classList.remove('active-back')
				}
			},
			onLeaveBack: () => {
				this.element.classList.remove('active-back')
			}
		})

		if(document.body.classList.contains('home')) {

			this.scrollTriggerInstanceForHP = ScrollTrigger.create({
				trigger: '.c-Hero',
				start: `top top`,
				end: 'bottom top',
				onEnterBack: () => {
					//console.log('enter back')
					this.element.style.backgroundColor = ''
				},
				onLeave: () => {
					//console.log('leave')
					this.element.style.backgroundColor = '#fff'
				},
			})
		}
	}

	handleClick() {
		window.emitter.emit('menuCalled')
	}

	unmount() {
		this.menuToggler.removeEventListener('click', this.handleClick)

		if(this.scrollTriggerInstanceForHP) this.scrollTriggerInstanceForHP.kill()
		if(this.scrollTriggerInstance) this.scrollTriggerInstance.kill()
		if(this.showAnim) this.showAnim.kill()
	}
}
