const Animations = {

	ease: {
		spring: "elastic.out(1, 0.2)",
		generic: "expo.out",
		circ: "circ.inOut",
		circOut: "circ.out",
		sin: "sin.inOut",
		powerTwo: "power2.inOut",
		powerFour: "power4.inOut"
	},

	timing: {
		generic: 1,
		titles: 1.4,
		clipPath: 1.2,
		button: 0.7,
		navbar: 0.6,
		links: 1,
		opacity: 1,
		gridItem: 1.8
	},

	stagger: {
		titles: 0.15,
		links: 0.05,
		gridItem: 0.2
	}
}

export { Animations }